<template>
  <div class="detailsDiv divMa">
    <div class="FHZJtou" @click="backPageUp"></div>
    <div class="detailsDiv1">{{details.typeName}}</div>
    <div class="detailsDiv2 bk">
      <img :src="staticurlSrc+details.typeImage">
      <div class="detailsDiv2-2">{{details.remark}}</div>
    </div>
    <div class="bk pdingZY20" v-html="details.typeDesc"></div>
  </div>
</template>

<script>
import'@/style/css/index.css';
import {staticurl} from "@/utils/baseurl";

export default {
  name: "SupportingServiceDetails",
  data(){
    return{
      staticurlSrc:staticurl,
      details:{}
    }
  },
  beforeMount(){
    let typeNo=this.$route.query.typeNo//url带参
    let key="SupportingServiceDetails"+typeNo;
    console.log(key)
    //sessionStorage.removeItem(key);//清空session
    const session =window.sessionStorage.getItem(key)
    console.log(session)
    if(!session){

    }else {
      this.details=JSON.parse(window.sessionStorage.getItem(key))
    }
  },
  methods: {
    backPageUp(){
      this.$router.go(-1)//跳转到上一页
    }
  }
}
</script>

<style scoped>
.detailsDiv{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: auto;
  height: auto;
}
.detailsDiv >>>img{
  width: 100%;
}

.detailsDiv1{
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 600;
  margin: 30px;
}
.detailsDiv2{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
}
.detailsDiv2 img{
  height: 100px;
  width: 100px;
}
.detailsDiv2-2{
  margin-left: 5px;
  line-height: 25px;
  font-size: 12px;
}
.FHZJtou{
  height: 30px;
  width: 30px;
  left: 20px;
  top: 20px;
  position: absolute;
  z-index: 333;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/images/FHZJtou.jpg");
  border-radius: 100%;
}
@media (max-width: 600px){
  .detailsDiv1{
    font-size: 20px;
  }
  .detailsDiv2{
    margin: 10px 0;
  }
  .FHZJtou{
    left: 10px;
    top: 10px;
  }
}
</style>
